import React from "react";
import { Grid, Icon, Item } from "semantic-ui-react";

import style from "./style.module.css";
import colors from "../colors";

const Title = ({ text }) => {
  if (!text) {
    return null;
  }
  return <Item.Header>{text}</Item.Header>;
};

const FeatureList = ({ features, siteSection, columns }) => {
  return (
    <div className={style.panel}>
      <Grid padded stackable>
        <Grid.Row columns={columns || 2}>
          {features.map(i => {
            return (
              <Grid.Column key={`feature-key-${i.title}`}>
                <Item.Group unstackable>
                  <Item>
                    <Item.Image
                      size="mini"
                      className={`${style.itemImage} ${style.specificallyNamed} ${style.extraItems} ${style.listenToMeBrowser}`}
                    >
                      <Icon
                        size="big"
                        name="check"
                        style={{
                          color: colors[siteSection].main
                        }}
                      />
                    </Item.Image>

                    <Item.Content>
                      <Title text={i.title} />

                      <Item.Description>
                        <p className={style.itemText}>{i.text}</p>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default FeatureList;
