import React from "react";

import style from "./style.module.css";
import colors from "../colors";

const Quote = ({ quote, quoteName, siteSection, reducedMargin }) => {
  if (!quote) {
    return null;
  }
  return (
    <div className={style.quoteWrapper}>
      <h1
        className={style.quoteMark}
        style={{ color: colors[siteSection].main }}
      >
        &ldquo;
      </h1>
      <div className={reducedMargin ? style.quoteInnerReducedMargin : style.quoteInner}>
        <blockquote
          className={style.quoteBody}
          style={{ color: colors[siteSection].main }}
        >
          {quote}
        </blockquote>
        <p className={style.quoteName}>{quoteName}</p>
      </div>
    </div>
  );
};

export default Quote;
