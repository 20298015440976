import React from "react";
import { useState } from "react";
import { Button, Form, Icon, Dropdown } from "semantic-ui-react";

import styles from "./contact-form-schools.module.css";
import colors from "../colors";

const selectOptions = [
  { text: 'Email from Rocksteady', value: 'Email from Rocksteady', key: 'Option_1' },
  { text: 'Letter from Rocksteady', value: 'Letter from Rocksteady', key: 'Option_2' },
  { text: 'Call from Rocksteady', value: 'Call from Rocksteady', key: 'Option_3' },
  { text: 'Feel Good Friday', value: 'Feel Good Friday', key: 'Option_4' },
  { text: 'Social Media', value: 'Social Media', key: 'Option_5' },
  { text: 'School Referral', value: 'School Referral', key: 'Option_6' },
].sort(() => 0.5 - Math.random());
selectOptions.push({ text: 'Other', value: 'Other', key: 'Option_7' });

const ContactFormSchools = ({ id }) => {
  const [selectedOption, setSelectedOption] = useState(undefined);

  const handleSelectChange = (e, { value }) => {
    setSelectedOption(value);
  }

  return (
    <div className={styles.contentAreaWrapper}>
      <Button className={styles.anchor} id="contact-form-schools" />
      <div className={styles.contentAreaInnerWrapper}>
        <h2>FREE IN-SCHOOL ROCK &amp; POP BAND LESSONS</h2>
        <p>Register your interest and we'll get back to you</p>
        <Form action={`https://formspree.io/f/${id}`} method="POST">
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="name">Your Name</label>
              <input name="name" id="name" aria-label="Your Name" placeholder="Your Name" required />
            </Form.Field>
            <Form.Field>
              <label htmlFor="email">Email</label>
              <input name="email" id="email" aria-label="Email" placeholder="Email" required type="email" />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="school">School Name</label>
              <input name="school" id="school" aria-label="School" placeholder="School" required />
            </Form.Field>
            <Form.Field>
              <label htmlFor="postcode">School Postcode</label>
              <input name="postcode" id="postcode" aria-label="Postcode" placeholder="Postcode" required />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="phone">Phone</label>
              <input name="phone" id="phone" aria-label="Phone" placeholder="Phone" required />
            </Form.Field>
            <Form.Field>
              <label htmlFor="role">Your Role</label>
              <input name="role" id="role" aria-label="Role" placeholder="Role" required />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label htmlFor="how_did_you_hear_about_us">How did you hear about us?</label>
              <Dropdown
                fluid
                selection
                id="how_did_you_hear_about_us"
                options={selectOptions}
                placeholder="Please select one"
                required
                onChange={handleSelectChange}
                value={selectedOption}
              />
              <input type="hidden" name="how_did_you_hear_about_us" aria-label="How did you hear about us?" value={selectedOption} />
            </Form.Field>
          </Form.Group>
          {
            selectedOption === 'Other' &&
            <Form.Group widths="equal">
              <Form.Field>
                <Form.TextArea
                  name="how_did_you_hear_about_us_notes"
                  id="how_did_you_hear_about_us_notes"
                  row={3}
                  required
                  label="Please tell us more"
                />
              </Form.Field>
            </Form.Group>
          }
          <Form.TextArea label="Your Message" name="message" rows={7} required />

          <Button
            type="submit"
            className={styles.submitButton}
            style={{
              backgroundColor: colors.secondary.cta
            }}
            floated="right"
          >
            Register Your Interest <Icon name="right arrow" />
          </Button>
        </Form>
      </div>
    </div>
  )
};

export default ContactFormSchools;
