import React, { useEffect, useState } from "react";
import { Container, Grid, Divider } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import FeatureList from "../../components/feature-list/feature-list";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import BodyContent from "../../components/body-content/body-content";
import Quote from "../../components/quote/quote";
import ContactFormSchools from "../../components/contact-form-schools/contact-form-schools";
import CookieUtils from '../../utils/cookie-utils';
import { SCHOOLS_PHONE } from "../../constants";

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  return (
    <LayoutInner>
      <SEO
          title="Information for Primary Schools"
          keywords={[`rocksteady`]}
          description="Find out about Rocksteady’s peripatetic rock and pop music band lessons for primary schools. Lessons support pupil wellbeing, complement music curriculum and contribute to academic progression."
      />

      <Container>
        <SecondaryNavBar
          product="primary"
          title="Information for Schools"
          titlePath="/info-for-schools/"
          titleActive={true}
          links={[
            {
              name: "Feel Good Friday",
              path: "/feelgoodfriday/",
              active: true,
              class: "menuButton button-smaller-text",
              mobile: true    
            }
          ]}
          cta="Free In-School Band Lessons"
          ctaMobile="Free In-School Band Lessons"
          ctaLink="#contact-form-schools"
        />

        <div className="videoWrapper wide">
          <iframe
            src={`https://player.vimeo.com/video/513312781${urlParams}`}
            width="640"
            height="480"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="Rocksteady Music School Primary"
          ></iframe>
        </div>

        <Divider hidden />
        <FeatureList
          siteSection="primary"
          features={[
            {
              title:
                "Band-based peripatetic music service delivered by professionals."
            },
            {
              title:
                "Inclusive programme that’s accessible to all pupils, supports wellbeing and contributes to academic progress."
            },
            { title: "Bursary, pupil premium and funded options available." },
            {
              title: "Free to your school."
            }
          ]}
        />

        <HeadingOverImage
          text="ROCKSTEADY IN-SCHOOL BAND LESSONS"
          img="/images/primary/bg_7.jpg"
          siteSection="primary"
        />

        <BodyContent>
          <Grid padded stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Quote
                  siteSection="primary"
                  quote="Parents (and staff) were impressed with what they heard and saw and it is clear that all children have progressed throughout the sessions both in confidence and musical ability."
                  quoteName="Dianne Carbon, Head Teacher St John Fisher Oxon"
                />
                <Quote
                  siteSection="primary"
                  quote="This is firstly to say how pleased we are with Rocksteady music. The children are thoroughly engaged in it and they love attending the sessions. Undertaking the concerts, they show confidence and resilience and these are qualities that are challenging to teach. Secondly I wanted to express to you that I was initially concerned that if we had Rocksteady in our school this would detract from our uptake of other peripatetic musical lessons: I have no concerns over that due to the fact that up take is steady and rising; in fact, with the addition of Rocksteady we are merely adding further enrichment to their musical education. Some students also undertake Rocksteady and the two forms of teaching do not sit in competition but complement each other: the children are learning a more formal system of music and at the same time a more expressive and experimental version- this offers them a wide range of musical experience."
                  quoteName="David Bowden, Head Teacher, Lyncrest Primary School, Northampton"
                />
                <Quote
                  siteSection="primary"
                  quote="Rocksteady have been working with pupils from our school for some time. The children are of mixed ages and music ability, most haven't even picked up an instrument before their first Rocksteady session! All the children have gained confidence and have fully embraced this new experience. I work with vulnerable children within my school, who find education a challenge and lack self-worth. I have noticed that since attending the Rocksteady classes they have become focused, behaviour in class is more positive and it has enabled these children to express themselves. Rocksteady has undoubtably increased their self-esteem and has given them the belief that they can shine!"
                  quoteName="Clouce Primary School"
                />
              </Grid.Column>
              <Grid.Column>
                <Quote
                  siteSection="primary"
                  quote="Please would you pass on just how brilliant the Rocksteady team have been. Hands down the best outside company coming into the school. The communication is fantastic, timetables that work and make sense are provided each week, the customer service is second to none and the kids are absolutely loving it! You just come in each week and get on with it and we are so happy!"
                  quoteName="Joseph Piatcczyan, Deputy Head St Peter Cranfield School"
                />
                <Quote
                  siteSection="primary"
                  quote="Paul is absolutely brilliant! He engages all the children (and staff) he works with and the kids just adore him! Paul's knowledge of how to get the best out of every individual he works with is just fantastic and he has helped those children to bloom, thrive and become so confident. When they perform at the concert, we can see how much progress they have made musically. Additionally this then transfers to everyday life as these children have become so much more articulate and positive. Paul is a credit to Rock Steady and the values you stand for. He is calm, kind and professional and frankly one of the loveliest people I have ever met. Thank you for all that you do in our school - Rocksteady makes such a difference to our children."
                  quoteName="Liz Nightingale, Head teacher, Green Park School"
                />
                <Quote
                  siteSection="primary"
                  quote="The fully-funded bursary spaces offered by Rocksteady Music School have provided some of our most vulnerable pupils with an amazing, life-changing opportunity to learn music. These children are often dealing with many stressors in their lives, which affect their social, emotional and mental health. As a school, we have really seen the positive impact of these children engaging in weekly music lessons: they have gained confidence, performing in front of the whole school at the end of term concerts, and made new friends, not to mention all of the benefits that come from listening to and playing music. To say that the experience has been ‘life-changing’ is not an exaggeration."
                  quoteName="Mei Lim, Head Teacher, Weyfield Academy"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <BodyContent>
          <h3>
            To find out more or to discuss availability in your area please get in
            touch using the form below or call {SCHOOLS_PHONE}
          </h3>
          <div>
            <ContactFormSchools id="mzbkdvbn" />
          </div>
        </BodyContent>
      </Container>
    </LayoutInner>
  )
}

export default Page;
